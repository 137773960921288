import React, { useCallback, useState } from "react";
import axios from "axios";
import { getSessionToken } from "@shopify/app-bridge-utils";

import {
  Button,
  Card,
  Form,
  FormLayout,
  Layout,
  TextField,
} from "@shopify/polaris";


// Global Variables:
const BASE_URL_RAILS = process.env.REACT_APP_BASE_URL_RAILS;



function CustomMetadataManual(props) {
  const app = props.app;
  const store = props.store;
  const product = props.product;

  // State Variables:
  const [inputFields, setInputFields] = useState(product.custom_metadata || []);


  const addFields = () => {
    // console.log('addFields:::2022-08-01');
    let newField = { title: '', value: '' };
    setInputFields([...inputFields, newField]);
  }



  const handleFormChange = (index, name, val) => {
    let data = [...inputFields];
    data[index][name] = val;
    setInputFields(data);
  };



  const handleSubmit = useCallback((_event) => {

    let form = [];
    for (let i = 0; i < _event.target.length; i++) {
      const element = _event.target[i];

      if (element.type === 'text') {
        console.log(`id: ${element.id}, name: ${element.name}, value: ${element.value}`)
        let index = parseInt(element.id) - 1;
        if (form[index] === undefined) {
          form[index] = {}
        }
        form[index][element.name] = element.value;
      }
    }

    getSessionToken(app).then((data) => {
      let token = data;

      var config = {
        method: 'post',
        url: `${BASE_URL_RAILS}/api/v0/shopify/products/update_custom_metadata`,
        headers: { 
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        data : {
          product_uuid: product.uuid,
          form: {
            input_fields: form
          }
        }
      };

      axios(config)
      .then(function (response) {
        var payload = response.data.payload;
      })
      .catch(function (error) {
        console.log(error);
      });
    });

  }, []);



  const removeFields = (index) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  }



  function DisplayMetadataDeleteButton(props) {
    const index = props.index;
    const boolLock = props.boolLock;
    
    let storeChecker = [
      "my-shop-example-1.myshopify.com",
      "certify-watches.myshopify.com",
    ];
    if (storeChecker.includes(store.store_url)) {
      return (<></>);
    }

    return (      
      <Button 
        destructive 
        onClick={() => removeFields(index)}
        disabled={boolLock}
      >X</Button>
    );
  }



  function DisplayNewMetadataFieldButton(props) {
    const addFields = props.addFields;
    const boolLock = props.boolLock;

    let storeChecker = [
      "my-shop-example-1.myshopify.com",
      "certify-watches.myshopify.com",
    ];
    if (storeChecker.includes(store.store_url)) {
      return (<></>);
    }
    
    return (
      <div style={{textAlign: 'center'}}>
        <Button
          onClick={addFields}
          primary
          disabled={boolLock}
        >+ New Metadata Field</Button>
      </div>
    )
  }



  let boolLock = (product.claim_status === 'claimed');
  return (
    <>
      <Layout.Section>
        <Card title="Digital Collectible Custom Metadata (Displays on OpenSea)" sectioned>
          <div style={{width: '50%'}}>
            <TextField
              value="Manual"
              label="Fulfilment Type"
              disabled
            />
          </div>

          <div style={{marginTop: '20px'}}>
            <Form onSubmit={handleSubmit}>
              <FormLayout>
              
                {inputFields.map((input, index) => {
                  return (
                    <FormLayout.Group key={index}>
                      <TextField
                        name="title"
                        value={input.title}
                        onChange={ val => handleFormChange(index, 'title', val)}
                        label="Title"
                        autoComplete="off"
                        id={index + 1}
                        helpText="E.g. Colour"
                        disabled={boolLock}
                      />

                      <TextField
                        name="value"
                        value={input.value}
                        onChange={ val => handleFormChange(index, 'value', val)}
                        label="Value"
                        autoComplete="off"
                        id={index + 1}
                        disabled={boolLock}
                        connectedRight={
                          <DisplayMetadataDeleteButton
                            index={index}
                            boolLock={boolLock}
                          ></DisplayMetadataDeleteButton>
                        }
                      />

                    </FormLayout.Group>
                  )
                })}

                <FormLayout.Group>
                  <DisplayNewMetadataFieldButton
                    addFields={addFields}
                    boolLock={boolLock}
                  ></DisplayNewMetadataFieldButton>
                </FormLayout.Group>

                <FormLayout.Group>
                  <Button submit primary disabled={boolLock}>Save</Button>
                </FormLayout.Group>

              </FormLayout>
            </Form>
          </div>





        </Card>
      </Layout.Section>
    </>
  );

}



export default CustomMetadataManual;